<template>
  <el-dialog width="600px">
    <div class="a">
      <div class="a-title">申请开票</div>

      <div>
        供方：{{ orderInfo.sellerEnterpriseName }}-{{ orderInfo.sellerName }}-{{
          orderInfo.sellerPhone
        }}
      </div>
      <div>
        买方：{{ orderInfo.buyerEnterpriseName }}-{{ orderInfo.buyerName }}-{{
          orderInfo.buyerPhone
        }}
      </div>
      <div class="a-content">
        <lidaForm
          :labelWidth="140"
          :span="1"
          :formValues="newBuy"
          ref="form"
          :formColumns="columns"
        />
      </div>
      <div class="a-footer">
        <el-button style="margin-right: 20px;" size="mini" @click="close"
          >取消</el-button
        >
        <el-button
          style="margin-left: 20px;"
          size="mini"
          type="primary"
          @click="confirm"
          >确认</el-button
        >
      </div>
    </div>
  </el-dialog>
</template>

<script>
import lidaForm from "@/components/publicComponent/lidaForm.vue";
import { mapActions } from "vuex";
import { ElMessage } from "element-plus";
import { log } from "util";
export default {
  data() {
    return {
      values: {},
      sell: {},
      arr: [],
      consig: {},
      newBuy: {},
    };
  },
  props: {
    oid: String,
    info: Object,
    orderInfo: {
      type: Array,
      default: () => {
        return [];
      },
    },
    type: { type: String, default: "add" }, //add 添加  detail 查看详情  edit修改
  },
  components: {
    lidaForm,
  },
  methods: {
    ...mapActions("mysaleMudule", [
      "invoiceCreate",
      "purchaseDetails",
      "userConsigneeDefault",
    ]),
    ...mapActions("myMessageModule", ["userGetUserInvoice", "userInvoiceEdit"]),
    close() {
      //关闭弹框
      this.$emit("update:modelValue", false);
    },
    confirm() {
      // console.log(this.values);
      if (this.type === "add") {
        this.$refs.form.$refs.form.validate((valid, newBuy) => {
          if (valid) {
            let data = JSON.parse(JSON.stringify(newBuy));
            if (data.area && data.area.length) {
              data.province = data.area[0] || null;
              data.city = data.area[1] || null;
              data.district = data.area[2] || null;
            }
            delete data.area;
            data.documentId = this.oid;
            data.invoiceType=='普票'?data.invoiceType='G':data.invoiceType='S'
            this.invoiceCreate(data).then((res) => {
              let { code, data } = res.data;
              if (code === "0") {
                ElMessage.success("申请成功");
                this.close();
                this.$emit("success");
              }
            });
          }
        });
      } else {
        this.close();
      }
    },
  },
  mounted() {},
  computed: {
    isEdit() {
      return this.type === "detail";
    },
    columns() {
      return [
        {
          label: "公司名称",
          prop: "invoiceHead",
          disabled: this.isEdit,
          rules: [{ message: "请输入公司名称", required: true }],
        },
        {
          label: "税务登记证号",
          prop: "taxpayerCode",
          disabled: this.isEdit,
          rules: [{ message: "请输入税务登记证号", required: true }],
        },
        {
          label: "公司开户行名称",
          prop: "bankName",
          disabled: this.isEdit,
          rules: [{ message: "请输入公司开户行名称", required: true }],
        },
        {
          label: "公司开户行账号",
          prop: "bankAccount",
          disabled: this.isEdit,
          rules: [{ message: "请输入公司开户行账号", required: true }],
        },
        {
          label: "注册场所地址",
          prop: "address",
          disabled: this.isEdit,
          rules: [{ message: "请输入注册场所地址", required: true }],
        },
        {
          label: "注册固定电话",
          prop: "invoicePhone",
          disabled: this.isEdit,
          rules: [{ message: "请输入注册固定电话", required: true }],
        },
        {
          label: "发票类型",
          prop: "invoiceType",
          type: "select",
          options: [
            { value: "普票", lable: "G" },
            { value: "专票", lable: "S" },
          ],
          disabled: this.isEdit,
          rules: [{ message: "请输入发票类型", required: true }],
        },
        {
          label: "收货人",
          prop: "consigneeName",
          disabled: this.isEdit,
          rules: [{ message: "请输入收货人", required: true }],
        },
        {
          label: "地址",
          prop: "area",
          type: "area",
          disabled: this.isEdit,
          rules: [{ message: "请选择地址", required: true }],
        },
        {
          label: "电话",
          prop: "consigneePhone",
          disabled: this.isEdit,
          rules: [{ message: "请输入电话", required: true }],
        },
        {
          label: "详细地址",
          prop: "receiveAddress",
          disabled: this.isEdit,
          rules: [{ message: "请输入详细地址", required: true }],
        },
      ];
    },
  },
  watch: {
    info(v) {
      //回显的数据
      let data = JSON.parse(JSON.stringify(v));
      data.area = [data.province, data.city, data.district];
      delete data.province;
      delete data.city;
      delete data.district;
      this.values = data;
    },
    orderInfo(v) {
      this.arr = v;
      this.userGetUserInvoice().then((res) => {
        this.sell = res.data.data;
        this.userConsigneeDefault(this.arr.buyerId).then((res) => {
          this.consig = res.data.data;

          this.newBuy = Object.assign(this.sell, this.consig);
        });
      });
    },
  },
};
</script>

<style scoped lang="less">
.a {
  font-size: 14px;
  padding: 20px;
  .a-title {
    font-weight: bold;
    padding: 0 20px 20px 0;
  }
  .a-footer {
    display: flex;
    justify-content: center;
  }
  .a-content {
    display: flex;
    align-items: stretch;
  }
  .a-left {
    flex: 5;
    .a-item {
      display: flex;
      align-items: center;
      padding-bottom: 60px;
      span {
        width: 140px;
        margin-right: 10px;
        text-align: right;
        i {
          margin-right: 4px;
          line-height: 20px;
          color: #ff1a1d;
        }
      }
    }
  }
  .a-right {
    flex: 3;
    box-sizing: border-box;
    padding-left: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .up-img {
      width: 180px;
      height: 180px;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #fbfdff;
      border-radius: 10px;
      border: 1px solid #eeeeee;
      overflow: hidden;
      p {
        font-size: 40px;
        color: #999999;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .a-r-title {
      text-align: center;
      padding-bottom: 20px;
      i {
        color: #ff1a1d;
        margin-right: 4px;
      }
    }
  }
}
</style>
